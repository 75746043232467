import React from "react";
import styles from './DeleteAccount.module.css'

const DeleteAccount: React.FC = () => {

  return (
    <div className={styles.page} >
      <div className={styles.container}>
        <div className={styles.contentWrapper}>
          <h1 className={styles.header1}>Requesting Account or Data Deletion</h1>
          <p>We respect your privacy and provide you with the ability to request access to or deletion of your personal data. Below, you’ll find the steps to initiate such requests, depending on your circumstances.</p>
          <h2 className={styles.header2}>How to Delete Your Account and Associated Data</h2>
          <p>If you have a registered account with us, you can request the deletion of your account and all associated data directly through the <b>Eatr</b> mobile app:</p>
          <ol>
            <li>Open the <b>Settings</b> tab in the app.</li>
            <li>Locate and select the <b>“Delete Account”</b> option.</li>
            <li>Follow the on-screen instructions to confirm your request.</li>
          </ol>
          <p>Once submitted, your data will be automatically deleted in accordance with our data retention and deletion policies.</p>
          <h2 className={styles.header2}>Requesting the Deletion of Specific Information</h2>
          <p>If you do not have access to your registered account or wish to request the removal of specific pieces of personal information rather than deleting your entire account, you may submit a request by contacting us at <a href="mail:eatrhelp@gmail.com" target="_blank" rel="noreferrer noopener"><b>eatrhelp@gmail.com</b></a>. Please include relevant details to help us process your request efficiently.</p>
          <h2 className={styles.header2}>Verification and Processing Your Request</h2>
          <p>To maintain the security and integrity of personal data, we are required to verify the identity of any individual making such a request. This means that your request must:</p>
          <ul>
            <li>Provide sufficient information for us to verify that you are the person to whom the data pertains or an authorized representative acting on their behalf.</li>
            <li>Clearly describe the request in adequate detail so that we can properly understand, evaluate, and fulfill it.</li>
          </ul>
          <p>We are unable to process requests or disclose personal data if we cannot verify your identity or confirm your authority to make the request. Ensuring the legitimacy of such requests helps protect user data from unauthorized access or misuse.</p>
          <h2 className={styles.header2}>Additional Considerations</h2>
          <ul>
            <li>You are not required to create an account to make a verifiable request.</li>
            <li>Any personal data you provide as part of your request will be used only for the purpose of verification and processing your request.</li>
            <li>Once your request has been verified and processed, we will confirm its completion or provide additional information if necessary.</li>
          </ul>
          <p>For further assistance or inquiries regarding data deletion, please don’t hesitate to reach out to us at <a href="mail:eatrhelp@gmail.com" target="_blank" rel="noreferrer noopener"><b>eatrhelp@gmail.com</b></a>. We are committed to handling all requests in compliance with applicable data protection regulations and ensuring a smooth and secure process for our users.</p>
        </div>
      </div>
    </div>
  )
}

export default DeleteAccount
